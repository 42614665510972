import { NodeType, TreeRecursiveChildrenNode } from '@/types/tree';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { useDialog } from '@/context/DialogContext';
import { useTree } from '@/hooks/data/tree/useTree';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useAlbumAssets } from '@/hooks/data/albums/useAlbumAssets';
import { useCommandContext } from '@/context/CommandContext';
import { useToast } from '@/components/ui/use-toast';
import { DraggableItems, DragItem } from '@/types/dragDrop';
import { AddAssetCommand } from '@/hooks/commands/albums/AddAssetCommand';
import { ToastAction } from '@/components/ui/toast';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { cn } from '@/lib/utils';
import { dropStyle, hoverStyles, selectedStyles, styles } from '@/components/sidebar/sidebar';
import { useDrag, useDrop } from 'react-dnd';
import { BookImage, Pen, Trash2Icon, Users } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { useCurrentPage } from '@/hooks/useCurrentPage';

type AlbumProps = {
  treeBranch: TreeRecursiveChildrenNode;
  parentId?: string;
  depth: number;
};

export function Album({ treeBranch, parentId, depth }: AlbumProps) {
  const findNodePath = useTreeStore((state) => state.findNodePath);
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);

  const { browseRedirection } = useCurrentPage();
  const { openModal } = useDialog();
  const { id, node, shared } = treeBranch;
  const { name, id: albumId } = node;
  const { query, push } = useRouter();
  const { setSelectedNodes, setCurrentSelectedType, selectedAlbum } = useTree();
  const { selectedAssetIds, deselectAllAssets } = useAssetSelection();
  const { addAssets, removeAssets } = useAlbumAssets();
  const { apply } = useCommandContext();
  const { toast } = useToast();

  const onSelectAlbum = useCallback(() => {
    deselectAllAssets();
    setSelectedNodes([{ id, name }]);
    setCurrentSelectedType(NodeType.Albums);

    const pathArray = findNodePath(id);

    setSelectedFolder(undefined);
    setSelectedAlbum(id);

    browseRedirection(pathArray);
  }, [id, node.id, push, query, selectedAlbum, setCurrentSelectedType, setSelectedNodes]);

  // drag'n drop configuration
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [DraggableItems.ASSET],
      canDrop: () => true,
      drop: async (item: DragItem) => {
        let assetIds = [item.id];
        if (item.type === DraggableItems.ASSET && selectedAssetIds?.length > 1) {
          assetIds = selectedAssetIds.map((asset) => asset.id);
        }
        const addAssetCommand = AddAssetCommand(addAssets, removeAssets, node.id, assetIds);
        const response = await apply(addAssetCommand);
        const assetsAdded = response.add ?? assetIds;
        const wereAssetsAdded = assetsAdded.length > 0;
        if (item.type === DraggableItems.ASSET) {
          toast({
            title: wereAssetsAdded ? 'Asset(s) added' : 'No asset has been added',
            description: wereAssetsAdded
              ? `${assetsAdded.length} asset(s) have been added successfully.`
              : 'The album already contains the asset(s)',
            action: wereAssetsAdded ? (
              <ToastAction
                onClick={() => removeAssets({ albumId: node.id, assetIds: assetsAdded })}
                altText="Undo add asset(s)"
              >
                Undo
                <ArrowUturnLeftIcon className="ml-1 size-3" />
              </ToastAction>
            ) : undefined,
          });
          deselectAllAssets();
        }
      },
      collect: (monitor) => ({
        isOver: Boolean(monitor.isOver()),
        canDrop: Boolean(monitor.canDrop()),
      }),
    }),
    [selectedAssetIds],
  );

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: DraggableItems.FOLDER,
    item: { type: DraggableItems.ALBUM, nodeId: id, currentParent: parentId } as DragItem,
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  }));

  useEffect(() => {
    if (isDragging && selectedAlbum !== node.id) {
      onSelectAlbum();
    }
  }, [isDragging, node.id, onSelectAlbum, selectedAlbum]);

  return (
    <ContextMenu>
      <ContextMenuTrigger className="flex-1">
        <li key={id} className="mt-px flex flex-col" ref={(ref) => drag(drop(preview(ref)))} title={name}>
          <div
            style={{ paddingLeft: `${depth * 15}px` }}
            className={cn(
              'flex w-full items-center rounded-md pr-1.5',
              styles,
              hoverStyles,
              selectedAlbum === node.id && selectedStyles,
              isOver && canDrop && dropStyle,
            )}
          >
            <button
              onClick={() => {
                onSelectAlbum();
              }}
              onContextMenu={() => {
                onSelectAlbum();
              }}
              className={cn('flex w-full items-center justify-start py-1 pl-6 outline-none')}
            >
              <BookImage className="mr-2 inline-block size-4 min-w-4 stroke-teal-600" strokeWidth={2} />
              <div className="flex w-full justify-between text-sm">
                <span className="line-clamp-1 break-all text-left">{name}</span>
                {/* placeholder for items counter */}
                {/* <Badge
                  className={cn(
                    'ml-2 whitespace-nowrap rounded-full px-2 py-0 text-[11px]',
                    selectedFolder === folderId
                      ? 'bg-neutral-100 dark:bg-neutral-900'
                      : 'bg-neutral-200 dark:bg-neutral-800',
                  )}
                  variant="secondary"
                >
                  {items.count}
                </Badge> */}
              </div>
              {shared && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Users className="inline-block size-4 min-w-4" strokeWidth={2} />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>This is a shared album</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              )}
            </button>
          </div>
        </li>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem onClick={() => openModal('editCreateFolderAlbum', 'renameAlbum', { id: node.id })}>
          <Pen className="mr-2 size-4" />
          Rename
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectAlbum();
            openModal('deleteConfirmation');
          }}
          className="text-red-600 focus:bg-red-100 focus:text-red-600"
        >
          <Trash2Icon className="mr-2 size-4" />
          Delete
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
