import { useDialog } from '@/context/DialogContext';
import { useTree } from '@/hooks/data/tree/useTree';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCommandContext } from '@/context/CommandContext';
import { useToast } from '@/components/ui/use-toast';
import { useSearch } from '@/context/SearchContext';
import { NodeType, TreeRecursiveChildrenNode } from '@/types/tree';
import { DraggableItems, DragItem } from '@/types/dragDrop';
import { MoveNodeCommand } from '@/hooks/commands/folder/MoveNodeCommand';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { ChevronRightIcon, DocumentArrowUpIcon } from '@heroicons/react/24/outline';

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { cn } from '@/lib/utils';
import { dropStyle, hoverStyles, selectedStyles, styles } from '@/components/sidebar/sidebar';
import { Branch } from '@/components/sidebar/tree/tree';
import { sortBy } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { FolderLock, FolderOpen, FolderPlus, LucideFolder, Pen, Trash2Icon } from 'lucide-react';
import { useCurrentPage } from '@/hooks/useCurrentPage';

type FolderProps = {
  treeBranch: TreeRecursiveChildrenNode;
  openIds: Array<string>;
  toggleNodeHandler: (id: string) => void;
  parentId?: string;
  depth: number;
};

export function Folder({ treeBranch, openIds, toggleNodeHandler, parentId, depth }: FolderProps) {
  const { openModal } = useDialog();
  const { setUpdatedIds } = useTree();
  const findNodePath = useTreeStore((state) => state.findNodePath);
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);
  const { id, node, children = [], with_children: withChildren } = treeBranch;
  const { name, locked = false, id: folderId } = node;
  const { query, push } = useRouter();
  const { selectedNodes, setSelectedNodes, setCurrentSelectedType, selectedFolder, moveNodes } = useTree();
  const { deselectAllAssets } = useAssetSelection();
  const { apply } = useCommandContext();
  const { toast } = useToast();
  const { setSearchInsideFolder } = useSearch();
  const { browseRedirection } = useCurrentPage();

  const isOpen = openIds.includes(id);

  const sortedChildren = sortBy(children, 'node.name');

  const onSelectFolder = useCallback(() => {
    deselectAllAssets();
    setSelectedNodes([{ id, name }]);
    setCurrentSelectedType(NodeType.Folders);
    setSearchInsideFolder(false);
    const pathArray = findNodePath(id);
    setSelectedAlbum(undefined);
    setSelectedFolder(id);

    browseRedirection(pathArray);
  }, [
    folderId,
    id,
    push,
    query,
    setCurrentSelectedType,
    setSelectedNodes,
    deselectAllAssets,
    name,
    setSearchInsideFolder,
  ]);

  // drag'n drop configuration
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [DraggableItems.FOLDER, DraggableItems.ASSET, DraggableItems.ALBUM],
      canDrop: () => true,
      drop: (item: DragItem) => {
        let assetIds = [item.nodeId];
        if (item.type === DraggableItems.ASSET && selectedNodes?.length > 1) {
          assetIds = selectedNodes.map((asset) => asset.id);
        }

        const moveNodeCommand = MoveNodeCommand(moveNodes, id, item.currentParent, assetIds);
        apply(moveNodeCommand);
        if (item.type === DraggableItems.ASSET) {
          toast({
            title: 'Asset(s) moved',
            description: `${selectedNodes.length} asset(s) have been moved successfully.`,
          });
          setSelectedNodes([]);
        }

        setUpdatedIds([id]);
      },
      collect: (monitor) => ({
        isOver: Boolean(monitor.isOver()),
        canDrop: Boolean(monitor.canDrop()),
      }),
    }),
    [selectedNodes, id, DraggableItems],
  );

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    canDrag: !locked,
    type: DraggableItems.FOLDER,
    item: { type: DraggableItems.FOLDER, nodeId: id, id: folderId, currentParent: parentId } as DragItem,
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  }));

  // select folder when dragging
  useEffect(() => {
    if (isDragging && selectedFolder !== folderId) {
      onSelectFolder();
    }
  }, [folderId, isDragging, onSelectFolder, selectedFolder]);

  return (
    <ContextMenu>
      <ContextMenuTrigger className="flex-1" ref={(ref) => drag(preview(ref))}>
        <li className="mt-px flex flex-col" title={name}>
          <div
            ref={drop}
            style={{ paddingLeft: `${depth * 15}px` }}
            className={cn(
              'flex w-full items-center rounded-md pr-1.5',
              styles,
              !isDragging && hoverStyles,
              selectedFolder === folderId && selectedStyles,
              isOver && canDrop && dropStyle,
            )}
          >
            {withChildren && (
              <div onClick={() => toggleNodeHandler(id)} className="group/chevron mr-px cursor-pointer select-none p-1">
                <div className="size-4 rounded duration-150 group-hover/chevron:bg-black/10 dark:group-hover/chevron:bg-white/10">
                  <ChevronRightIcon
                    className={cn('size-4 p-0.5 duration-150', {
                      'rotate-90': isOpen,
                    })}
                    strokeWidth={2}
                  />
                </div>
              </div>
            )}
            <button
              className={cn('flex w-full items-center justify-start py-1 outline-none', {
                'ml-6': !withChildren,
              })}
              onClick={onSelectFolder}
              onContextMenu={onSelectFolder}
            >
              {locked ? (
                <FolderLock
                  className="mr-2 size-4 min-w-4 stroke-neutral-500 dark:stroke-neutral-400"
                  strokeWidth={2}
                />
              ) : isOpen ? (
                <FolderOpen className="mr-2 size-4 min-w-4 stroke-violet-500" strokeWidth={2} />
              ) : (
                <LucideFolder className="mr-2 size-4 min-w-4 stroke-violet-500" strokeWidth={2} />
              )}
              <div className="flex w-full justify-between text-sm">
                <span className="line-clamp-1 break-all text-left">{name}</span>
                {/* placeholder for items counter */}
                {/* <Badge
                  className={cn(
                    'ml-2 whitespace-nowrap rounded-full px-2 py-0 text-[11px]',
                    selectedFolder === folderId
                      ? 'bg-neutral-100 dark:bg-neutral-900'
                      : 'bg-neutral-200 dark:bg-neutral-800',
                  )}
                  variant="secondary"
                >
                  {items.count}
                </Badge> */}
              </div>
            </button>
          </div>
          {isOpen && (
            <ul className="relative flex flex-col">
              <div
                className="absolute inset-y-1 z-20 ml-3 block w-px bg-neutral-600/10 opacity-0 transition-opacity duration-300 group-hover/tree:opacity-100 dark:bg-neutral-200/10"
                style={{ left: `${depth * 15}px` }}
              />
              <Branch
                tree={sortedChildren}
                openIds={openIds}
                toggleNodeHandler={toggleNodeHandler}
                parentId={id}
                depth={depth + 1}
              />
            </ul>
          )}
        </li>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('uploadAsset');
            }
          }}
        >
          <DocumentArrowUpIcon className="mr-2 size-4" />
          New Upload
        </ContextMenuItem>

        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('editCreateFolderAlbum', 'createFolder', { id: selectedFolder });
            }
          }}
        >
          <FolderPlus className="mr-2 size-4" />
          New Folder
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('editCreateFolderAlbum', 'createAlbum', { id: selectedFolder });
            }
          }}
        >
          <FolderPlus className="mr-2 size-4" />
          New Album
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          disabled={locked}
          onClick={() => openModal('editCreateFolderAlbum', 'renameFolder', { id: folderId })}
        >
          <Pen className="mr-2 size-4" />
          Rename
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            openModal('deleteConfirmation');
          }}
          className="!text-red-600 focus:!bg-red-100/50 dark:focus:!bg-red-950/50"
        >
          <Trash2Icon className="mr-2 size-4" />
          Delete
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
