import { useRouter } from 'next/router';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { DefaultError, useMutation, useQueryClient } from '@tanstack/react-query';
import { removeAlbumAssets, addAlbumAssets, reorderAlbumAssets } from '@/services/album.service';
import { AddAssetsResponse, RemoveAssetsResponse, ReorderAssetsParams } from '@/types/album';
import { AssetListQueryKeyPrefix, useAssetList } from '@/hooks/data/assets/useAssetList';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';

export const useAlbumAssets = () => {
  const addAlbumAssetsQueryFn = useAuthenticatedQueryFn(addAlbumAssets);
  const removeAlbumAssetsQueryFn = useAuthenticatedQueryFn(removeAlbumAssets);
  const reorderAlbumAssetsQueryFn = useAuthenticatedQueryFn(reorderAlbumAssets);

  const { query } = useRouter();
  const { results, page } = query;
  const album = useTreeStore((state) => state.selectedAlbum);
  const folder = useTreeStore((state) => state.selectedFolder);

  const { refetch } = useAssetList(
    Number(page ?? 1),
    Number(results ?? 10),
    folder && String(folder),
    album && String(album),
  );

  const { mutateAsync: addAssets } = useMutation<
    AddAssetsResponse,
    DefaultError,
    Parameters<typeof addAlbumAssets>['0']
  >({
    mutationFn: (params) => addAlbumAssetsQueryFn(params),
  });

  const { mutateAsync: removeAssets } = useMutation<
    RemoveAssetsResponse,
    DefaultError,
    Parameters<typeof removeAlbumAssets>['0']
  >({
    mutationFn: (params) => removeAlbumAssetsQueryFn(params),
    onSettled: () => {
      void refetch();
    },
  });

  const { mutateAsync: reorderAssets } = useMutation<
    ReorderAssetsParams,
    DefaultError,
    Parameters<typeof reorderAlbumAssets>['0']
  >({
    mutationFn: (params) => reorderAlbumAssetsQueryFn(params),
    onSettled: () => {
      void refetch();
    },
  });

  return {
    addAssets,
    removeAssets,
    reorderAssets,
  };
};
