import React, { RefObject, useState } from 'react';
import Image from 'next/image';
import { File, FileText, FileImage, FileVideo, FileAudio, Play } from 'lucide-react';
import { FileType } from '@/types/fileType';

export type AssetThumbnailProps = {
  name: string;
  fileType?: string;
  thumbnail?: string;
  iconSize: number;
  draggable?: boolean;
  width?: number;
  height?: number;
  imageRef?: RefObject<HTMLImageElement>;
  iconContainerRef?: RefObject<HTMLImageElement>;
  onDoubleClick?: () => void;
  iconStrokeWidth?: number;
  fill?: boolean;
  onLoad?: () => void;
  imageClassName?: string;
  iconClassName?: string;
  imageContainerClassName?: string;
  iconContainerClassName?: string;
};

export const AssetThumbnail: React.FC<AssetThumbnailProps> = ({
  name,
  fileType,
  thumbnail,
  iconSize,
  onLoad,
  draggable = false,
  width,
  height,
  imageRef,
  iconContainerRef,
  onDoubleClick,
  iconStrokeWidth = 1,
  fill = true,
  imageClassName = '',
  iconClassName = '',
  imageContainerClassName = 'relative',
  iconContainerClassName = '',
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const renderImage = (src: string) => (
    <div className={imageContainerClassName}>
      <Image
        onDoubleClick={onDoubleClick}
        width={width}
        height={height}
        ref={imageRef}
        alt={name}
        onLoad={onLoad}
        onError={handleImageError}
        src={src}
        draggable={draggable}
        fill={fill}
        className={imageClassName}
      />
      {fileType === FileType.Video && (
        <div className="absolute bottom-1 left-1 text-neutral-950 dark:text-white">
          <Play size={18} strokeWidth={3} />
        </div>
      )}
    </div>
  );

  const renderIcon = (IconComponent: React.ElementType) => (
    <div ref={iconContainerRef} className={iconContainerClassName}>
      <IconComponent className={iconClassName} strokeWidth={iconStrokeWidth} size={iconSize} />
    </div>
  );

  if (thumbnail && !imageError) {
    return renderImage(thumbnail);
  } else {
    switch (fileType) {
      case FileType.Image:
        return renderIcon(FileImage);
      case FileType.Video:
        return renderIcon(FileVideo);
      case FileType.Document:
        return renderIcon(FileText);
      case FileType.Audio:
        return renderIcon(FileAudio);
      case FileType.Other:
      default:
        return renderIcon(File);
    }
  }
};
