import { FC, useMemo, useState } from 'react';
import { cn } from '@/lib/utils';
import { Asset, Variants } from '@/types/asset';
import { Skeleton } from '@/components/ui/skeleton';
import { FileType } from '@/types/fileType';
import { AssetThumbnail } from '@/components/asset-thumbnail';
import { VideoPlayer } from '@/components/video-player';

export const InspectorImage: FC<Asset & { stackedAssetsLength: number; index: number; classname?: string }> = ({
  id,
  stackedAssetsLength,
  name,
  index,
  variants,
  type,
  versions,
  classname,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const thumbnailUrl = variants[Variants.ThumbnailInternal]?.url;

  const fileType = useMemo(() => {
    return type ? type : versions ? versions[0]?.fileType : FileType.Other;
  }, [versions, type]);

  return (
    <div
      className={cn(
        'flex size-full origin-center overflow-hidden rounded-md shadow-lg transition-all duration-200',
        classname,
      )}
      key={`inspector-${id}`}
      style={{
        transform: `rotate(${(30 / stackedAssetsLength) * index}deg)`,
        zIndex: stackedAssetsLength - index,
      }}
    >
      {Object.entries(variants).length === 0 && thumbnailUrl && !isLoaded ? (
        <Skeleton className="size-full rounded-none" />
      ) : fileType === FileType.Video ? (
        <VideoPlayer variants={variants} />
      ) : (
        <AssetThumbnail
          name={name}
          fileType={fileType}
          thumbnail={thumbnailUrl}
          iconSize={200}
          imageClassName="size-auto object-cover"
          iconClassName="size-14 stroke-neutral-500 dark:stroke-neutral-700"
          imageContainerClassName=""
          iconContainerClassName="flex size-full items-center justify-center rounded-md bg-white hover:cursor-pointer dark:bg-neutral-950"
        />
      )}
    </div>
  );
};
