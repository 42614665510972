import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Updater,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { DataTablePagination } from '@/components/data-table/data-table-pagination';
import { RowEntity } from '@/context/DatatableSelectionContext';
import { useUserSettings } from '@/context/UserSettingsContext';
import { setLocalStorage } from '@/utilities/localStorage';
import { PaginationState, defaultPaginationIndex } from '@/types/pagination';
import { SortState } from '@/types/sort';
import { useRouter } from 'next/router';

// import { DataTableToolbar } from '@/components/metadata-table/data-table-toolbar';

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>;
  data: Array<TData>;
  options?: Array<{ value: string; label: string; initials: string }>;
  pagination?: PaginationState;
  sort?: SortState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  withPagination?: boolean;
  rowCount?: number;
  entity: string;
  queryString: string;
  setSelectedEntity: any;
  setSelectedEntityType: any;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  // options,
  pagination,
  setPagination,
  withPagination = true,
  rowCount,
  entity,
  queryString,
  sort,
  setSelectedEntity,
  setSelectedEntityType,
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { setShowInspector } = useUserSettings();
  const router = useRouter();
  const { query, replace, pathname } = router;

  const setQueryParams = (pageIndex: number, pageSize: number, value: string, order: string, qString: string) => {
    const newQuery = {
      ...query,
      pageIndex: String(pageIndex),
      pageSize: String(pageSize),
      sortValue: value,
      sortOrder: order,
      queryString: qString,
    };

    void replace(
      {
        pathname: pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    if (pagination && setPagination) {
      setPagination({ pageIndex: defaultPaginationIndex, pageSize: pagination.pageSize });
    }
  }, [sort?.value, sort?.order, queryString]);

  useEffect(() => {
    if (pagination && sort) {
      setQueryParams(pagination.pageIndex + 1, pagination.pageSize, sort.value, sort.order, queryString);
    }
  }, [sort?.value, sort?.order, pagination?.pageIndex, pagination?.pageSize, queryString]);

  const setDatatablePagination = (updater: Updater<PaginationState>) => {
    if (typeof updater !== 'function') {
      return;
    }
    const oldPageInfo = table.getState().pagination;
    const newPageInfo = updater(oldPageInfo);
    setLocalStorage(`${entity}/datatable/pagination/pageSize`, String(newPageInfo.pageSize));
    if (oldPageInfo.pageSize !== newPageInfo.pageSize) {
      newPageInfo.pageIndex = defaultPaginationIndex;
    }
    if (setPagination) {
      setPagination(newPageInfo);
    }
  };
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility: {
        id: false,
        user_id: false,
      },
      rowSelection,
      columnFilters,
      pagination,
    },
    rowCount: rowCount,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onPaginationChange: setDatatablePagination,
    manualPagination: true, //we're doing manual "server-side" pagination
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (row) => (row as { id: string }).id,
  });

  useEffect(() => {
    const rowId = Object.keys(rowSelection)[0];
    setSelectedEntity(rowId ? (table.getRow(rowId) as RowEntity) : null);
    setSelectedEntityType(entity);
    setShowInspector(true);
  }, [rowSelection]);

  return (
    <div className="flex w-full grow flex-col overflow-y-auto">
      {/* <DataTableToolbar table={table} className="px-6" options={options} /> */}
      <div className="flex h-full overflow-hidden">
        <Table>
          <TableHeader className="rounded-none">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  onClick={row.getToggleSelectedHandler()}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row
                    .getVisibleCells()
                    .map((cell: { id: string; column: { columnDef: any }; getContext: () => any }) => (
                      <TableCell key={cell.id} className={cell.column.columnDef.meta?.cellClassName}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {withPagination && <DataTablePagination table={table} className="px-3 py-2" />}
    </div>
  );
}
